$(document).ready(function () {
  $('.delete-btn').click((e) => {
    e.preventDefault();
    
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success btn-sm',
        cancelButton: 'btn btn-danger btn-sm'
      },
      buttonsStyling: false
    })

    swalWithBootstrapButtons.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        $.ajax({
          type: "DELETE",
          url: e.target.href,
          data: {},
          dataType: "json",
          success: function (data) {
            if (data.error !== undefined){
              window.location.replace('/');

              return
            }

            window.location.replace(e.target.dataset.redirectUrl);
              
            swalWithBootstrapButtons.fire(
              'Deleted!',
              'Successfully Deleted.',
              'success'
            )

          },
          error: function (data) {
            console.log('An error occurred.');
            console.log(data);
          },
        });


      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          'Cancelled',
          'Not Deleted :)',
          'error'
        )
      }
    })
  })
})